@mixin defaultFont() {
  //--ion-font-family: Calibri, Optima, Consolas, Arial, Trebuchet, sans-serif;
  //--ion-default-font : Calibri, Optima, Consolas, Arial, Trebuchet, sans-serif;
  --ion-font-family: "hind", Arial, Trebuchet, sans-serif;
  --ion-default-font : "hind", Arial, Trebuchet, sans-serif;
}

/*
@font-face {
  font-family: "comiczine";
  src: url($documentRoot + 'assets/fonts/comic_zine_ot.otf');
}
*/

@font-face {
  font-family: "hind";
  src: url($documentRoot + 'assets/fonts/hind-medium.otf');
}

/*
@font-face {
  font-family: "inriasans";
  src: url($documentRoot + 'assets/fonts/InriaSans-Regular.otf');
}
*/
