.no-shadow-dom-support {

  app-hunter-page,
  app-tagger-page {
    ion-header,
    ion-footer {
      ion-col {
        padding: 0.25rem;
      }
    }
  }

  button {
    &.button-native {
      padding-left: var(--padding-start);
      padding-right: var(--padding-end);
    }
  }

  flash-message {
    display: block;
    height: 100%;
  }

  .inner-scroll {
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
  }

  ion-header {
    padding: var(--padding-start);
  }

  ion-grid {
    padding: var(--padding-start);
  }

  .map-container {
    height: 100%;
  }

  .select-icon {
    margin-right: var(--padding-end);
  }

  .select-text {
    padding-left: var(--padding-start);
  }
}