/*
@font-face {
  font-family: "comiczine";
  src: url($documentRoot + 'assets/fonts/comic_zine_ot.otf');
}
*/
@font-face {
  font-family: "hind";
  src: url("/app/assets/fonts/hind-medium.otf");
}
/*
@font-face {
  font-family: "inriasans";
  src: url($documentRoot + 'assets/fonts/InriaSans-Regular.otf');
}
*/
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-font-family: "hind", Arial, Trebuchet, sans-serif;
  --ion-default-font: "hind", Arial, Trebuchet, sans-serif;
  font-size: 1.2rem;
}

:root button.help {
  font-size: 1.1rem;
  color: var(--ion-color-light);
  padding: 6px 7px 4px;
  background: var(--ion-color-primary);
  border-radius: 20px;
}
:root .beta-version {
  background-color: rgba(var(--ion-color-primary-rgb), 0.5);
  color: var(--ion-color-light);
  margin-bottom: 2rem;
}
:root .beta-version a {
  color: var(--ion-color-light);
}
:root .disclaimer-modal {
  --width: 90%;
  --height: 90%;
  --border-radius: 6px;
  --border-color: var(--ion-color-dark);
  --border-width: 1px;
}
:root .error {
  border: 1px solid var(--ion-color-danger);
  padding: 5px;
  border-radius: 4px;
}
:root .fitness-modal {
  --width: 88%;
  --height: 460px;
  --border-radius: 6px;
  --border-color: var(--ion-color-dark);
  --border-width: 1px;
}
:root .game-name {
  background: rgba(var(--ion-color-dark-rgb), 0.8);
  padding: 0.3rem;
  border-radius: 4px;
  text-align: center;
}
:root h1 {
  margin-top: 0;
  font-family: "hind";
  color: var(--ion-color-light);
  font-size: 40px;
}
:root h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--ion-color-light);
  font-size: 1.3rem;
  font-weight: bold;
}
:root h3 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: bold;
}
:root ion-button {
  font-size: 1rem;
}
:root ion-button.button-disabled {
  --background: #707070;
}
:root ion-button[size=small] {
  font-size: 0.8rem;
}
:root ion-button.round {
  border-radius: 20px;
}
:root ion-card-content {
  font-size: 1rem;
}
:root ion-col.number {
  text-align: right;
}
:root ion-select {
  box-shadow: 0px 0px 3px var(--ion-color-primary);
}
:root ion-spinner {
  vertical-align: bottom;
}
:root section {
  margin-bottom: 2rem;
}
:root .small {
  font-size: 88%;
}
:root .submit {
  text-align: center;
}
:root ul.nav {
  padding: 0;
  margin: 0;
}
:root ul.nav li {
  display: inline;
  padding: 0;
  margin: 0 0.25rem 0 0;
}
:root .valign {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
:root .with-subtitle {
  margin-bottom: 0;
}

body {
  color: #000000;
}

html.ios {
  --ion-font-family: "hind", Arial, Trebuchet, sans-serif;
  --ion-default-font: "hind", Arial, Trebuchet, sans-serif;
}

ion-toast.above-footer {
  --ion-safe-area-bottom: 80px;
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes brightToDark {
  from {
    background: white;
  }
  to {
    background: rgba(0, 0, 0, 0.5);
  }
}
@keyframes flash {
  0% {
    box-shadow: var(--ion-color-danger) 0px 0px 0px;
  }
  50% {
    box-shadow: var(--ion-color-danger) 0px 0px 8px;
  }
  100% {
    box-shadow: var(--ion-color-danger) 0px 0px 0px;
  }
}