@keyframes pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes brightToDark {
  from {
    background: rgba(255,255,255,1);
  }

  to {
    background: rgba(0,0,0,0.5);
  }
}

@keyframes flash {
  0% {
    box-shadow: var(--ion-color-danger) 0px 0px 0px;
  }

  50% {
    box-shadow: var(--ion-color-danger) 0px 0px 8px;
  }

  100% {
    box-shadow: var(--ion-color-danger) 0px 0px 0px;
  }

}