:root {
  button {
    &.help {
      font-size: 1.1rem;
      color: var(--ion-color-light);
      padding: 6px 7px 4px;
      background: var(--ion-color-primary);
      border-radius: 20px;
    }
  }

  .beta-version {
    background-color: rgba(var(--ion-color-primary-rgb), 0.5);
    color: var(--ion-color-light);
    margin-bottom: 2rem;

    a {
      color: var(--ion-color-light);
    }
  }

  .disclaimer-modal {
    --width: 90%;
    --height: 90%;
    --border-radius: 6px;
    --border-color: var(--ion-color-dark);
    --border-width: 1px;
  }

  .error {
    border: 1px solid var(--ion-color-danger);
    padding: 5px;
    border-radius: 4px;
  }

  .fitness-modal {
    --width: 88%;
    --height: 460px;
    --border-radius: 6px;
    --border-color: var(--ion-color-dark);
    --border-width: 1px;
  }

  .game-name {
    background: $darkBackground;
    padding: 0.3rem;
    border-radius: 4px;
    text-align: center;
  }

  h1 {
    margin-top: 0;
    font-family: "hind";
    color: var(--ion-color-light);
    font-size: 40px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--ion-color-light);
    font-size: 1.3rem;
    font-weight: bold;
  }

  h3 {
    margin-top: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  ion-button {
    font-size: 1rem;

    &.button-disabled {
      --background: #707070;
    }

    &[size="small"] {
      font-size: 0.8rem;
    }

    &.round {
      border-radius: 20px;
    }
  }

  ion-card-content {
    font-size: 1rem;
  }

  ion-col {
    &.number {
      text-align: right;
    }
  }

  ion-select {
    box-shadow: 0px 0px 3px var(--ion-color-primary);
  }

  ion-spinner {
    vertical-align: bottom;
  }

  section {
    margin-bottom: 2rem;
  }

  .small {
    font-size: 88%;
  }

  .submit {
    text-align: center;
  }

  ul {
    &.nav {
      padding: 0;
      margin: 0;

      li {
        display: inline;
        padding: 0;
        margin: 0 0.25rem 0 0;
      }
    }
  }

  .valign {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .with-subtitle {
    margin-bottom: 0;
  }
}


body {
  color: #000000;
}

html {
  &.ios {
    @include defaultFont();
  }
}



ion-toast {
  &.above-footer {
    --ion-safe-area-bottom: 80px;
  }
}

